.left8>img{
    width: 350px;
    /* border-radius: 20px; */
        /* height: 90%; */
}
.left8{
    /* width: 30%; */

}
.right8{
    width: 30%;
}
.cartt{
    gap: 2rem;
    display: flex;
    align-items: center;
    margin-top: -40px;
}
.cart55 {
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-color: gray; */

    margin-top: 60px;
}
.cartpara{
    width: 100%;
}
.heading-coffee {
    font-size: 20px;
    /* margin-top: 1px; */
    /* width: 30%; */
    letter-spacing: 2px;
}
.heading-c1{
    font-size: 15px;
    font-weight: 100;
} 
.flavour-coffeez{
    letter-spacing: 3px;
    font-size: 39px;
    margin-top: 50px;
}
.ull{
    display: flex;
}
.highlight{
    margin-top: -10px;
    font-size: 20px;
    letter-spacing: 2px;
}
.drop2{
    margin-top: 5px;
}
.price-no{
    /* width: 150px; */
}
.pricee{
    display: flex;
    flex-direction: column;
    margin-top: -30px;
}


/* CSS */
.button-4 {
        margin-top: -20px;
    width: 100px;
  appearance: none;
  background-color:#754C29;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color:white;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 11px 32px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}



.button-4:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: black;
  cursor: default;
}

.button-4:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
  color: black;
}

.button-4:focus {
  outline: 1px transparent;
}

.button-4:before {
  display: none;
}

.button-4:-webkit-details-marker {
  display: none;
}
.border{
    width: 77%;
    /* gap: 2rem; */
    background-color: whitesmoke;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
@media only screen and (max-width: 1150px) {

    .border {
   
        /* gap: 4rem; */
     
    }
    
}
@media only screen and (max-width: 1100px) {

    .cartpara {
        width: 120%;
    }
}
/* @media only screen and (min-width: 1150px) {

    .border {
   
        gap: 4rem;
     
    }
    
} */
.review{
    white-space: pre;
}
@media only screen and (max-width: 1000px) {

    .border {
   
        /* gap: 6rem; */
     
    }
    
}
@media only screen and (max-width: 900px) {

    .border{
        flex-direction: column;
        align-items: center;
    }
    .flavour-coffeez {
        text-align: center;
        margin-top: -32px;
    }
    .cartt {
        gap: 0rem;
        display: flex;
        margin-top: -40px;
        align-items: center;
        flex-direction: column;
    }
    .star1{
        margin-top: 30px;
    }
    .review{
        margin-top: 10px;
    }
    .review2 {
        margin-top: -10px;
    }
    .highlight{
        text-align: center;
    }
    .cartpara {
        width: 95%;
        text-align: center;
    }
    .pricee {
      
        align-items: center;
    }
    .right8 {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .border {
        width: 94%;
        /* gap: 2rem; */
        background-color: whitesmoke;
        border-radius: 30px;
        box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px 0px;
    }
    .left8>img {
        width: 214px;
        /* border-radius: 20px; */
        /* height: 80%; */
    }
    .flavour-coffeez {
        letter-spacing: 3px;
        font-size: 39px;
        margin-top: 5px !important;
    }
}

@media only screen and (max-width: 500px) {


    .left8>img {
        width: 214px;
        /* border-radius: 20px; */
        /* height: 80%; */
    }
    .flavour-coffeez {
        text-align: center;
        margin-top: -23px;
    }

}

@media only screen and (max-width: 600px) {

.border {
    width: 94%;
    /* gap: 2rem; */
    background-color: whitesmoke;
    border-radius: 30px;
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px 0px;
}
}

.flavour-coffeez {
    letter-spacing: 3px;
    font-size: 39px;
    margin-top: 145px;
}