@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

.section2{
  
    margin-top: -221px;
}
.heading1{
    text-align: center;
    font-size: 4rem;
    letter-spacing: 8px;
    font-weight: 100;
    color: aliceblue;
    font-family: 'Kaushan Script', cursive;
}
.back-about{
    background-image: url('../img2/cafe2.jpg');
height: 50vh;
    background-size:     cover;                      
    background-repeat:   no-repeat;
    background-position: center center;
    /* background-attachment: fixed;  */
    /* opacity: 0.8; */
    /* filter: brightness(50%); */
    /* margin-top: -80px; */
}
.image-div{
    width: 30%;
}
.image-div>img{
    width: 100%;
    border-radius: 20px;
}
.section3{
    display: flex;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 7rem;
}
.section4{
    display: flex;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 50px;

}
.section5{
    display: flex;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 50px;

}
.textsss{
    width: 29%;
}
.about-para{
    font-size: 19px;
    color: #ad9a74; 
}
.heading-2{
    font-size: 35px;
   color: black;
    line-height: 0px;
    letter-spacing: 2px;
}
@media screen and (max-width: 960px) {

    .section3{
        flex-direction: column;
    }
    
    .section4{
        flex-direction: column-reverse;
    }
    
    .section5{
        flex-direction: column;
    }
    .image-div{
        width: 74%;
    }.textsss{
        width: 74%;
    }
    .media-qu{
        display: flex !important;
        flex-wrap: wrap;
    }
    .media-qq{
        display: none;
    }
}
.media-qu{
    display: none;
}
